import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoaderService } from "src/app/core/loading/loader.service";
import { User } from "src/app/core/models/user";
import { NotificationsService } from "src/app/core/notifications/notifications.service";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { WalletService } from "src/app/core/services/wallet.service";
import { ParticipantLoginCommand } from "src/app/dtos/participantLoginCommand";
import { UsersService } from "../../users/users.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  token: string;
  tokenType: string;
  expiresIn: number;
  loginForm: FormGroup;
  errors: { [key: string]: string[] };

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UsersService,
    private walletService: WalletService,
    private router: Router,
    private notifications: NotificationsService,
    private loaderService: LoaderService
  ) {
    this.authenticationService.clearStorage();

    let params = new URLSearchParams(router.url.split("#")[1]);

    this.token = params.get("access_token");
    this.tokenType = params.get("token_type");
    this.expiresIn = Number(params.get("expires_in"));
    // Amplify.configure({
    //   aws_cognito_region: "us-east-2",
    //   aws_user_pools_id: "us-east-2_3zMrpB4po", // (optional) -  Amazon Cognito User Pool ID
    //   aws_user_pools_web_client_id: "4u16u9tnk21nddvmn6fqdivl0l", // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
    //   Analytics: {
    //     disabled: true,
    //   },
    // });
    if(!router.url.includes('login')){
      router.navigate([""]);
    }
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      userName: [null, Validators.compose([Validators.required])],
      password: [null, Validators.required],
    });
  }

  async submit() {
    let loginDTO: ParticipantLoginCommand = {
      pan: !this.formControls.userName.value.includes("@")
        ? this.formControls.userName.value
        : null,
      email: this.formControls.userName.value.includes("@")
        ? this.formControls.userName.value
        : null,
      password: this.formControls.password.value,
    };

    this.walletService.login(loginDTO).subscribe(
      (userCognito) => {
        if (userCognito && userCognito.accessToken) {
          const user: User = new User();
          user.token = userCognito.accessToken;
          user.refreshToken = userCognito.refreshToken;
          let expiresAt = new Date(
            new Date().getTime() + userCognito.expiredIn * 1000
          );
          user.expiresTokenAt = expiresAt;
          user.secondsRefreshToken = userCognito.expiredIn;
          this.authenticationService.setCurrentUser(user);

          //FIND USER PROFILE
          this.userService.getProfile().subscribe(
            (result) => {
              //UPDATE USER LOGAL STORAGE
              user.firstName = result.firstName;
              user.lastName = result.lastName;
              user.pan = result.userName;
              user.language = result.language;
              user.email = result.email;
              user.phoneNumber = result.phoneNumber;

              this.authenticationService.setCurrentUser(user);
              this.router.navigate(["/protal"]);
            },
            (error) => {
              this.authenticationService.logout();
            }
          );
        }
      },
      (error) => {
        if (error.errors) this.errors = error?.errors;
        else this.notifications.error("", error);
      }
    );
  }
  public get formControls(): any {
    return this.loginForm.controls;
  }
  validUser() {
    if (!this.formControls.userName.value.includes("@")) {
      if (this.formControls.userName.value.length !== 16) return false;
    }

    return true;
  }
  signUp() {
    this.router.navigate(["/auth/sign-up"]);
  }
}
