import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SignUpCommand } from "src/app/dtos/signUpCommand";
import { SignUpResponse } from "src/app/dtos/signUpResponse";
import { SignUpValidateAccountCommand } from "src/app/dtos/signUpValidateAccountCommand";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class ParticipantSignUpService extends BaseService {
  constructor(protected readonly http: HttpClient) {
    super(http);
    this.setBaseUrl("ParticipantSignUp");
  }

  signUp(dto: SignUpCommand): Observable<SignUpResponse> {
    return this.http.post<SignUpResponse>(this.buildUrl(`/SignUp`), dto);
  }

  validateCardAccount(dto: SignUpValidateAccountCommand): Observable<SignUpResponse> {
    return this.http.post<SignUpResponse>(this.buildUrl(`/ValidateCardAccount`), dto);
  }

}
