export const environment = {
  version: require("../../package.json").version,
  production: false,
  staging: true,
  development: false,
  apiUrl: "https://apimobiletest2.soliportal.com/api/v1",
  notificationsUrl: "http://localhost:5000/notificationhub",
  cognitoClientId: "4u16u9tnk21nddvmn6fqdivl0l",
  cognitoRegion: "us-east-2",
  cognitoUserPoolId: "us-east-2_3zMrpB4po",
  program: [
    {
      state: 'NM',
      stateName: 'New Mexico',
    },
    {
      state: 'LA',
      stateName: 'Louisiana',
    },
    {
      state: 'CA',
      stateName: 'Test abc'
    },
    {
      state: 'AK',
      stateName: 'Zeta'
    }, 
    {
      state: 'CT',
      stateName: 'Connecticut'
    },
    {
      state: 'AL',
      stateName: 'Alabama'
    }
  ]
};
