import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { CreditCardDirectivesModule } from "angular-cc-library";
import { NgxCaptchaModule } from "ngx-captcha";
import { NgxMaskModule } from "ngx-mask";
import { NgxSelectModule } from "ngx-select-ex";
import { PhoneModule } from "src/app/shared/phone.module";
import { SharedModule } from "src/app/shared/shared.module";
import { LoginComponent } from "./login/login.component";
import { RecoverComponent } from "./recover/recover.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SignUpNewComponent } from "./sign-up-new/sign-up-new.component";
import { SignUpUpdatesComponent } from "./sign-up-updates/sign-up-updates.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { SignUpStateComponent } from './sign-up-state/sign-up-state.component';

const routes: Routes = [
  //{ path: '', redirectTo: 'login', pathMatch: 'full' },
  //{ path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    NgxSelectModule,
    CreditCardDirectivesModule,
    NgxMaskModule.forRoot(),
    NgxCaptchaModule,
    TranslateModule,
    NgbModule,
    PhoneModule
  ],
  declarations: [
    LoginComponent,
    RecoverComponent,
    ResetPasswordComponent,
    SignUpComponent,
    SignUpUpdatesComponent,
    SignUpNewComponent,
    SignUpStateComponent,
  ],
})
export class AuthenticationModule {}
